import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Styles = styled.div`
    .footer-wrapper {
        background: #333333;
        color: white;
    }

    .credits {
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
`;

export const Footer = () => {
    return (
        <Styles>
            <div className="footer-wrapper">
                <Container>
                    <div className="credits">
                        Designed & Built by Kevin Huynh
                    </div>
                </Container>
            </div>
        </Styles>
    );
};
