import React from "react";
import Home from "./Home";
import Projects from "./Project";
import Volunteer from "./Volunteer";

import { Footer } from "./Standard/Footer";
import styled from "styled-components";

const Styles = styled.div`


]
`;

const Main = () => {
    return (
        <Styles>
            <div>
                <Home />
                <Projects />
                <Volunteer />
                <Footer />
            </div>
        </Styles>
    );
};

export default Main;
