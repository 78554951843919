import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";
import styled from "styled-components";

const Styles = styled.div`
    .nav > .active {
        color: black;
    }
    .items {
        padding: 10px 10px;
        color: #4169e1;
        text-transform: uppercase;
        margin: 2px;
    }

    a {
        text-decoration: none;
        padding: 10px;
        border: white 2px solid;
    }

    a:hover {
        color: #222222;
        border-bottom: 2px black solid;
        cursor: pointer;
    }
`;

export const MainNavbar = () => {
    return (
        <Styles>
            <Navbar className="navbar" expand="lg">
                <Navbar.Brand></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto nav">
                        <Link
                            activeClass="active"
                            to="projects"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            className="items"
                        >
                            Projects
                        </Link>

                        <Link
                            activeClass="active"
                            to="volunteer"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            className="items"
                        >
                            Volunteer
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Styles>
    );
};
