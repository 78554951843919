import React from "react";
import styled from "styled-components";
import { Badge, Card, Container, Row, Col } from "react-bootstrap";
import GitHubIcon from "../Icons/Github";
import trf from "../assets/raise_logo_background.png";

const Styles = styled.div`
    h1 {
        display: flex;
        justify-content: center;
        color: black;
        position: relative;
        z-index: 1;
    }

    #projects {
        // height: 100vh;
        padding-top: 2rem;
    }

    .project-grid {
        @media not all and (max-width: 770px) {
            position: relative;
            bottom: 8rem;
        }
    }

    .hov {
        margin-bottom: 4rem;
        border: none;
    }

    .hov #project-image {
        @media (max-width: 770px) {
            max-height: 350px;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            max-height: 210px;
        }

        background: white;
        display: flex;
        justify-content: center;
        padding: 0;
        position: relative;
        z-index: 2;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @media not all and (max-width: 700px) {
            transform: translateY(150px);
            transition: 0.5s;
        }
    }

    .hov:hover #project-image {
        transform: translateY(0px);
        transition: 0.5s;
        border: none;
    }

    .hov #project-desc {
        position: relative;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @media not all and (max-width: 700px) {
            border-top: none;
            border-radius: 0px 0px 10px 10px;
            transform: translateY(-75px);
            transition: 0.5s;
        }
    }
    .hov:hover #project-desc {
        transform: translateY(0px);
        transition: 0.5s;
    }

    .project-img {
        opacity: 1;
        transition: 0.5s;
    }

    a {
        padding: 10px;
        svg {
            width: 1.5rem;
            height: 1.5rem;
            transition: 0.3s;
            fill: grey;
        }
    }

    a:hover {
        text-decoration: none;
    }

    svg:hover {
        fill: #4169e1;
        transform: translateY(-2px);
        transition: 0.3s;
    }

    .project-row {
        display: flex;
        justify-content: center;
    }

    .project-col {
        position: relative;
        top: -80px;
    }
`;

const Projects = () => {
    const projects = [
        {
            name: "The Raise Foundation Training Portal",
            image: trf,
            description:
                "A training portal to license and certify upcoming child-serving professionals using React and AWS",
            gitHub: "https://github.com/blueprintatuci/the-raise-foundation-training-portal",
        },
        {
            name: "Fitting Room",
            image: "https://personal-platform.s3.us-east-2.amazonaws.com/FittingRoom.png",
            description:
                "Web platform that allows the fashion community to share and connect with each other",
            inProgress: true,
            gitHub: "https://github.com/mickychetta/fittingroom",
        },
        {
            name: "Someone's Shoes",
            image: "https://personal-platform.s3.us-east-2.amazonaws.com/Someone's+Shoes.png",
            description:
                "Multi-platform built with Google Hangouts and News API for the youth to create global and authentic dialogue",
            gitHub: "https://github.com/jerseycity19/team-7",
        },
        // {
        //     name: "myThread",
        //     image:
        //         "https://personal-platform.s3.us-east-2.amazonaws.com/myThread.png",
        //     description:
        //         "Android application that allows for users to post, rate, and reply to threads using Android Studio and Firebase",
        //     gitHub: "https://github.com/khuynh09/my-reddit-thread-app",
        //     class: "project-col",
        // },
    ];

    return (
        <Styles>
            <div id="projects">
                <h1>Projects</h1>
                <Container className="mt-5 project-grid">
                    <Row className="project-row">
                        {projects.map((p, index) => (
                            <Col key={index} className={p.class} md={4}>
                                <Card className="hov">
                                    <Card.Body id="project-image">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: 350,
                                                height: 350,
                                            }}
                                        >
                                            <Card.Img
                                                className="project-img"
                                                src={p.image}
                                            ></Card.Img>
                                        </div>
                                    </Card.Body>
                                    <Card.Body id="project-desc">
                                        <Card.Text>{p.description}</Card.Text>
                                        {p.gitHub && (
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href={p.gitHub}
                                                class="github-icon"
                                            >
                                                <GitHubIcon></GitHubIcon>{" "}
                                            </a>
                                        )}
                                        {p.inProgress && (
                                            <Badge
                                                pill
                                                style={{
                                                    color: "white",
                                                    "background-color":
                                                        "#4169e1",
                                                }}
                                            >
                                                In Progress
                                            </Badge>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </Styles>
    );
};

export default Projects;
