import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MainNavbar } from "./Components/Standard/MainNavbar.js";
import Main from "./Components/Main.js";

class App extends Component {
    render() {
        return (
            <div>
                <React.Fragment>
                    <MainNavbar />
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Main} />
                        </Switch>
                    </Router>
                </React.Fragment>
            </div>
        );
    }
}

export default App;
