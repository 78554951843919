import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Jumbotron, Image, Row, Col } from "react-bootstrap";
import { Card, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faSchool } from "@fortawesome/free-solid-svg-icons";
import GitHubIcon from "../Icons/Github";
import LinkedInIcon from "../Icons/LinkedIn";
import EmailIcon from "../Icons/Email";
import ResumeIcon from "../Icons/Resume";
import { Element } from "react-scroll";

const Styles = styled.div`
    .header {
        color: grey;
        text-align: center;
        padding: 10px;
        padding-top: 5rem;
    }

    .typewriter h1 {
        @media not all and (max-width: 640px) {
            color: grey;
            font-family: "Montserrat", sans-serif;
            overflow: hidden; /* Ensures the content is not revealed until the animation */
            border-right: 0.25rem solid #4169e1;
            white-space: nowrap; /* Keeps the content on a single line */
            margin: 0 auto; /* Gives that scrolling effect as the typing happens */
            animation: typing 2s steps(30, end),
                blink-caret 0.5s step-end infinite;
            text-align: center;
        }
    }

    @keyframes typing {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }

    .image {
        display: flex;
        background: white;
        width: auto;
    }

    .avatar {
        width: 314.5px;
        height: 314.69px;
    }

    .jumbo {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        margin-top: 0;
        padding: 0;
        padding-top: 1rem;
    }

    .about {
        padding-top: 3rem;
        padding-bottom: 2rem;
        text-align: center;
        color: white;
        line-height: 2rem;
    }

    .about-container {
        padding: 0 2rem;
        @media not all and (max-width: 480px) {
            padding: 0 5rem;
        }
        margin-bottom: 10rem;
    }

    .bio-row {
        padding-bottom: 10rem;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .bio {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        background-color: #4169e1;
    }

    .rolesDiv {
        position: relative;
        bottom: 9rem;
        z-index: 1;
    }

    .roles {
        display: flex;
        justify-items: center;
        text-align: center;
    }

    .card {
        min-height: 790px;
    }

    .student {
        border-radius: 0px 25px 25px 0px;
        border-left: 1px #f2f2f2 solid;
        @media (max-width: 600px) {
            border-radius: 0px 0px 25px 25px;
        }
    }

    .developer {
        border-radius: 25px 0px 0px 25px;
        border-right: 1px #f2f2f2 solid;
        @media (max-width: 600px) {
            border-radius: 25px 25px 0px 0px;
        }
    }

    .roles-title {
        color: #222222;
        margin-top: 1rem;
        font-weight: bold;
    }

    .roles-icon {
        color: #222222;
        margin-top: 2rem;
    }

    .roles-text {
        @media not all and (max-width: 480px) {
            margin: 1rem 2rem;
        }
    }

    .roles-subtitle {
        margin-top: 3rem;
        margin-bottom: 1rem;
        color: #4169e1;
    }

    li {
        @media not all and (max-width: 770px) {
        }
        margin-bottom: 1rem;
    }

    a {
        padding: 10px;

        svg {
            width: 1.5rem;
            height: 1.5rem;
            transition: 0.3s;
            fill: grey;
        }
    }

    svg:hover {
        fill: #222222;
        transform: translateY(-2px);
        transition: 0.3s;
    }

    .socials {
        padding: 0;
        width: 40px;
        position: fixed;
        top: 170px;
        left: 20px;
        z-index: 10;
    }
`;

const Home = () => {
    const [isMobile, setMobile] = useState(window.innerWidth < 770);

    const updateMedia = () => {
        setMobile(window.innerWidth < 770);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <Styles>
            <Element id="home">
                <Jumbotron className="jumbo">
                    <div className="header typewriter">
                        <h1>Hi, I'm Kevin. Nice to meet you.</h1>
                    </div>
                </Jumbotron>
                <div className="image">
                    {!isMobile ? (
                        <ul
                            className="socials"
                            style={{ "list-style-type": "none" }}
                        >
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://github.com/khuynh09"
                                >
                                    <GitHubIcon></GitHubIcon>
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://www.linkedin.com/in/khuynh09/"
                                >
                                    <LinkedInIcon></LinkedInIcon>
                                </a>
                            </li>
                            <li>
                                <a href="mailto: kevinhuynh914@gmail.com">
                                    <EmailIcon></EmailIcon>
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://kevinhuynh.s3.us-east-2.amazonaws.com/Kevin+Huynh+Resume+FT.pdf"
                                >
                                    <ResumeIcon></ResumeIcon>
                                </a>
                            </li>
                        </ul>
                    ) : (
                        <div></div>
                    )}
                    <Image
                        className="avatar mx-auto d-block"
                        src="https://kevinhuynh.s3.us-east-2.amazonaws.com/Avatar+Pic.jpg"
                        roundedCircle
                        fluid
                    />
                </div>
                <div className="bio">
                    <div className="about">
                        <Container className="about-container">
                            <p>
                                Stepping into this field, I saw computer science
                                as a valuable skill of this generation and
                                something to exercise/challenge my desire to
                                create and display. Now, I have completed my{" "}
                                <b>B.S in Computer Science</b> at
                                <b> University of California, Irvine </b> while
                                pursuing my career and dreams as a full stack
                                developer.
                            </p>

                            <p>
                                I am currently a Software Development Engineer
                                at <b>Amazon Web Services</b>.
                            </p>
                        </Container>
                    </div>
                </div>
                <div className="rolesDiv">
                    <Container className="roles">
                        <Row>
                            <Col md={6} style={{ padding: 0 }}>
                                <Card className="card developer">
                                    <Card.Body>
                                        <FontAwesomeIcon
                                            icon={faCode}
                                            className="roles-icon"
                                        />
                                        <Card.Title className="roles-title">
                                            Full Stack Developer
                                        </Card.Title>
                                        <Card.Text className="roles-text">
                                            I enjoy building and bringing my own
                                            ideas into a platform. My creativity
                                            gets to be displayed.
                                        </Card.Text>
                                        <Card.Subtitle className="roles-subtitle">
                                            Languages:
                                        </Card.Subtitle>
                                        <Card.Text className="roles-text">
                                            Javascript, Typescript, HTML, CSS
                                        </Card.Text>
                                        <Card.Subtitle className="roles-subtitle">
                                            Development Tools and Frameworks:{" "}
                                        </Card.Subtitle>
                                        <Card.Text className="roles-text">
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    padding: 0,
                                                }}
                                            >
                                                <li>React / React Redux</li>
                                                <li>Vue</li>
                                                <li>Angular</li>
                                                <li>NodeJS</li>
                                                <li>Docker</li>
                                                <li>RabbitMQ</li>
                                                <li>PostgreSQL</li>
                                            </ul>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={6} style={{ padding: 0 }}>
                                <Card className="card student">
                                    <Card.Body>
                                        <FontAwesomeIcon
                                            icon={faSchool}
                                            className="roles-icon"
                                        />
                                        <Card.Title className="roles-title">
                                            Student
                                        </Card.Title>
                                        <Card.Text className="roles-text">
                                            At UCI, I spent my time learning and
                                            developing a deeper understanding
                                            with back-end fundamentals.
                                        </Card.Text>
                                        <Card.Subtitle className="roles-subtitle">
                                            Languages:
                                        </Card.Subtitle>
                                        <Card.Text className="roles-text">
                                            Python, C++, Java, SQL
                                        </Card.Text>
                                        <Card.Subtitle className="roles-subtitle">
                                            Fundamentals:{" "}
                                        </Card.Subtitle>
                                        <Card.Text className="roles-text">
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    padding: 0,
                                                }}
                                            >
                                                <li>
                                                    Object Oriented Programming
                                                </li>
                                                <li>Data Structures</li>
                                                <li>Algorithms</li>
                                                <li>Inheritance</li>
                                                <li>
                                                    Application Programming
                                                    Interfaces
                                                </li>
                                            </ul>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Element>
        </Styles>
    );
};

export default Home;
