import React from 'react';
import styled from 'styled-components'
import blueprintLogo from "../assets/blueprint_logo_only.png";
import dscLogo from "../assets/dsc_logo.png";

import { Card, Container, Row, Col } from 'react-bootstrap';


const Styles = styled.div`
#volunteer{
}

.volunteer-bg{
    padding-top: 2rem;
    background: #4169e1;
    height: 300px;

}
h1{
    display: flex;
    justify-content: center;
    color: white;
    position:relative;
    z-index:1;
}

.volunteer-container{
    position: relative;
    bottom: 250px;
    z-index: 1
}

.v-card{
    margin: 10px 0px
}

.card-container{
    border-radius: 25px;
}

.blueprint-logo  {
    padding: 10px;
    background-color: #0078e8;
    width: 10%;
    margin: 0;

}

.dsc-logo  {
    width: 10%
    
}

.title{
    padding-left: 1rem;
    color: #222222;
    font-weight: bold
}
`;


const Volunteer = () => {

    return (
        <Styles>
            <div id="volunteer">
                <div className="volunteer-bg"><h1>Volunteer</h1></div>   
                <Container className="mt-5 volunteer-container">
                    <Row>
                        <Col className="v-card" sm={12} >
                            <Card className='card-container'>
                                <Card.Body style={{paddingBottom: '0px', display: 'flex', alignItems: 'center'}}>
                                    <Card.Img className="blueprint-logo"
                                        src={blueprintLogo}></Card.Img>
                                    <span className="title"> Blueprint @ UCI</span>
                                </Card.Body>
                                <Card.Body>
                                    <Card.Text>
                                        <div className="mb-4">Blueprint at UCI's mission is to provide pro bono assistance for non-profit organizations by designing and creating software tools and utilities, such as websites, and mobile applications to further their own mission. We are made up of highly motivated students at UCI who have a passion for giving back to the community by applying what we learn and doing what we do best: make software.</div>
                                        <div>With a team of 10+, I work along with developers to reach out to nonprofit organizations and build software to excel their missions</div>
                                        <div>As a <b>Project Lead Developer</b>, I am responsible for breaking down tasks for both front end and back end development teams to ensure task are completed in a timely manner. 
                                            We are currently working with the Raise Foundation to develop a training portal to license and certify upcoming child-serving professionals</div>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="v-card" sm={12} >
                            <Card className='card-container'>
                                <Card.Body style={{paddingBottom: '0px', display: 'flex', alignItems: 'center'}}>
                                    <Card.Img className="dsc-logo"
                                        src={dscLogo}></Card.Img>
                                    <span className="title"> Google Student Developer Club @ UCI</span>
                                </Card.Body>
                                <Card.Body >
                                    <Card.Text>
                                        <div className="mb-4 ">Student run organization supported and recognized by
                                            <b style={{fontSize: '1.1rem'}}>
                                                <span style={{color: '#4285f4'}}> G</span>
                                                <span style={{color: '#ea4335'}}>o</span>
                                                <span style={{color: '#f7bc45'}}>o</span>
                                                <span style={{color: '#4285f4'}}>g</span>
                                                <span style={{color: '#4da954'}}>l</span>
                                                <span style={{color: '#ea4335'}}>e </span>
                                            </b>
                                            
                                            Developers that empowers students through technology to make impact in their community.</div>
                                        <div>
                                            I have collaborated with a team of 16 to grow a community of 30+ student developers with a mission to build
                                            solutions for local business and communities. I designed presentations and graphics using Google technologies to showcase UI/UX design principles</div>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>

                        
                    </Row>
                </Container>
            </div>

        </Styles >


    );
}

export default Volunteer;